import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useAnimations } from "../effects/Effects"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.div`
width: 100%;
min-height: 80vh;
background: #FFF;
background-size: cover;
z-index: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
padding: 5vh 0vw;

`

const ContainerIdTarget = styled.div`
height: 0px;
width: 0px;
position: absolute;
top: -10vh;
left: 0px;

@media ${device.sm} {
top: -15vh;
}
`

const Title = styled.h2`
width: 100%;
text-align: center;
color: #965F8A;
font-size: 36px;
font-weight: 600;
margin-bottom: 70px;
text-transform: uppercase;
`

const ContentContainer = styled.div`
width: fit-content;
display: grid;
grid-template-columns: repeat(3, 1fr);
column-gap: 50px;
row-gap: 30px;

@media ${device.lg} {
    grid-template-columns: repeat(2, 1fr);
}

@media ${device.md} {
    grid-template-columns: repeat(1, 1fr);
}
/* 
& > a:nth-of-type(3) {

    @media ${device.lg} {
    left: 25%;
    grid-column-start: 1;
    grid-column-end: 3; 
}

    @media ${device.md} {
        width: 70vw;
        left: 0;

        grid-column-start: 1;
        grid-column-end: 2;
    }
} */

`


const CardContainer = styled.a<{ img?: string }>`
width: 350px;
height: 250px;
border: 1px solid #4ac6b7;
box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
display: flex;
flex-direction: column-reverse;
position: relative;
overflow: hidden;
cursor: pointer;

@media ${device.md} {
    width: 70vw;
}

@media ${device.sm} {
    height: 25vh;
}

@media ${device.xs} {
    height: 25vh;
}

& > h3 {
    font-weight: 700;
    padding: 15px;
    bottom: 0;
    background-color: white;
    font-size: 16px;
    border-top: 1px solid #e1e1e1;
    z-index: 2;
    color: #333;

    @media ${device.sm} {
        font-size: 14px;
    }
}

&:focus {
    h3 {
        outline: none;
        color: #965F8A;
    }
}


& > img {
    width: 100px;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 40px;
    left: 0;
    right: 0;
    transform: translateY(100px) scale(0);
    transition: all 0.5s ease;
    z-index: 1;
    opacity: 0;
}

& > div:first-of-type {
    width: 100%;
    height: 100%;
    position: relative;

    ${({ img }) => img ? css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    ` : css`
        background: rgba(88, 196, 128, 0.73);
    `}

}



@keyframes blur {
    0% {
        filter: blur(0px);
    }
    100% {
        filter: blur(2px);
    }
}

&:hover {

    & > img {
        transform: translateY(0px) scale(1);
        opacity: 1;
    }
    
    & > div:first-of-type {
        filter: blur(2px);
        animation: blur 0.5s ease;

    }

}

`

const NewCardContainer = styled.a`
height: 250px;
width: 350px;
border: 2px dashed #965F8A;
display: flex;
flex-direction: column-reverse;
position: relative;
align-self: center;
transition: all 0.3s ease;
overflow: hidden;
cursor: pointer;

@keyframes gradient-animation_2 {
    0% {
      transform: translateX(-2000%);
    }
  
    100% {
      transform: translateX(100%);
    }
  }

grid-column-start: 2;
grid-column-end: 3;


@media ${device.lg} {
    left: 25%;
    grid-column-start: 1;
    grid-column-end: 3; 
}


@media ${device.md} {
    width: 70vw;
    left: 0;

    grid-column-start: 1;
    grid-column-end: 2;
}

@media ${device.sm} {
    height: 25vh;

}

@media ${device.xs} {
    height: 25vh;
}


@media ${device.md} {
    width: 70vw;
}

@media ${device.sm} {
    height: 25vh;
}

@media ${device.xs} {
    height: 25vh;
}

& > h3 {
    font-weight: 700;
    padding: 15px;
    bottom: 0;
    background-color: white;
    font-size: 16px;
    border-top: 1px solid #e1e1e1;
    color: #965F8A;
    text-align: center;
    transition: all 0.3s ease;

    @media ${device.sm} {
        font-size: 14px;
    }
}

& > img {
    width: 50px;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 40px;
    left: 0;
    right: 0;
}

&:hover {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    border: 2px dashed rgb(74, 198, 183);
    background-color: rgba(146, 221, 211, 0.2);

    & > h3 {
    color: #4ac6b7;
    }
}
`

export const Portfolio = () => {

    const { handleLogEvent } = useLogEvent();

    const {
        handleClickGoMeetings,
    } = useAnimations()

    return (

        <Container>

            <ContainerIdTarget id="portfolio" />

            <Title className='utils__fade-in-fast'>Portfolio 🚀</Title>

            <ContentContainer className=''>

                <CardContainer
                    target="_blank"
                    href="https://srandamios.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/andamios-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_sr_andamios') }}
                >

                    <h3>SR Andamios</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/srandamios-logo.webp`} alt="srandamios-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://estebanpersonal.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/esteban-personal-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_esteban_personal_trainer') }}
                >

                    <h3>Esteban Personal Trainer</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/logo-esteban-personal.png`} alt="logo-esteban-personal" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://nidexlogistica.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/nidex-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_nidex_logistica') }}
                >

                    <h3>Nidex Logística</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/nidex-logo.png`} alt="nidex-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://wasabifest.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/wasabi-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_wasabi') }}
                >

                    <h3>Wasabi Fest</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/wasabi-logo.png`} alt="wasabi-logo" />

                </CardContainer>


                <CardContainer
                    target="_blank"
                    href="https://aluviher.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/aluviher-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_aluviher') }}
                >

                    <h3>Aluviher</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/aluviher-logo.png`} alt="aluviher-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://fiebrefiesta.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/fiebre-intro.png`}
                    onClick={() => { handleLogEvent('click_portfolio_fiebre') }}
                >

                    <h3>Fiebre Fiesta</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/fiebre-logo.png`} alt="fiebre-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://falherramientas.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/fal-bg1.jpg`}
                    onClick={() => { handleLogEvent('click_portfolio_fal_herramientas') }}
                >

                    <h3>FAL Herramientas</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/fal-logo.png`} alt="fal-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://glowink.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/glowink-bg.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_glowink') }}
                >

                    <h3>GlowInk</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/glowink-logo.png`} alt="glowink-logo" />

                </CardContainer>

                <CardContainer
                    target="_blank"
                    href="https://faetanitechos.com.ar"
                    rel="noreferrer"
                    img={`${process.env.REACT_APP_CDN}/faetani-intro.webp`}
                    onClick={() => { handleLogEvent('click_portfolio_faetani_techos') }}
                >

                    <h3>Faetani Techos</h3>

                    <div />

                    <img src={`${process.env.REACT_APP_CDN}/faetani-logo.webp`} alt="faetanitechos-logo" />

                </CardContainer>

                {/* <CardContainer style={{ cursor: 'default' }}>

                    <div />

                    <CommingSoon>
                        <h3>Proximamente</h3>
                    </CommingSoon>

                    <CommingSoon>
                        <h3>Proximamente</h3>
                    </CommingSoon>

                </CardContainer> */}


                <NewCardContainer
                    onClick={async () => {
                        handleClickGoMeetings();

                        handleLogEvent('workTogetherPortfolio');
                    }}
                >

                    <h3>¡Trabajemos juntos!</h3>

                    <img src={`${process.env.REACT_APP_CDN}/agregar.png`} alt="agregar" />

                </NewCardContainer>

            </ContentContainer>

        </Container>

    )
}
