import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
width: 100%;
min-height: 80vh;
background: #FFF;
background-size: cover;
z-index: 1;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
padding: 5vh 0vw;
`

const ContainerIdTarget = styled.div`
height: 0px;
width: 0px;
position: absolute;
top: -10vh;
left: 0px;

@media ${device.sm} {
top: -10vh;
}
`

const ContentContainer = styled.div`
width: 100%;
height: 80vh;

@media ${device.lg} {
    height: 120vh;
}

@media ${device.md} {
    height: 120vh;
}

@media ${device.sm} {
    height: 120vh;
}

@media ${device.xs} {
    height: 170vh;
}

iframe {
    height: 100%;
    width: 100%;
    border: none;
}

`

const Title = styled.h2`
width: 80%;
text-align: center;
color: #965F8A;
font-size: 36px;
font-weight: 600;
margin-bottom: 70px;
text-transform: uppercase;

`

export const Meetings = () => {

    return (

        <Container>

            <ContainerIdTarget id="meetings" />

            <Title className='utils__fade-in-fast'>Charlemos un rato</Title>

            <ContentContainer className='utils__fade-in-fast'>

                <iframe
                    title="Calendly"
                    src="https://calendly.com/batataok/hace-realidad-tu-pagina-web"
                    width="100%"
                    height="120vh"
                />

            </ContentContainer>

        </Container>

    )
}
